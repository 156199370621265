import React from "react";
import "./pagenotfound.scss";

const PageNotFound = () => {
  return (
    <div className="pagenotfound">
      <h1>Oops! looks like page routing url is not matching.......</h1>
    </div>
  );
};

export default PageNotFound;
